<template>
  <div>
    <h1 class='text-left font-semibold text-lg lg:text-2xl uppercase mt-0 lg:mt-4'>Important Dates</h1>
    <div v-for='(importantDate, index) in importantDates'
      :key='`important-date-main-${index}`'
      class='text-right my-2 py-3 pr-4 pl-16'
      style='background-color:#FAFAFA;'>
      <h3 class='text-sm lg:text-base uppercase'>
        {{ importantDate.title }}
      </h3>
      <h2 class='text-xl lg:text-2xl'>
        <span 
          v-if='daysUntil(importantDate.date) <= 0'
          class='uppercase text-gray-700 text-lg inline-block bg-gray-200 p-0.5 mr-1'>
          Deadline Past
        </span>
        <span :class='daysUntil(importantDate.date) <= 0 ? "line-through text-gray-500" : ""'>
          {{ formatDateMonthString(importantDate.date) }}
          <span class='font-semibold'>{{ formatDateDayString(importantDate.date) }}</span>
        </span>
      </h2>
      <div v-if='daysUntil(importantDate.date) > 0'
        class='relative rounded-full pl-1'
        style='background-color:#EFEEF0;'>
        <h4 class='text-left text-xs font-gray-700'>{{ daysUntilString(importantDate.date) }}</h4>
        <div 
          class='absolute top-0 right-0 rounded-full h-full'
          :style='progressBarStyle(importantDate.date)'>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs  from 'dayjs'
import chroma from 'chroma-js'
import { mapGetters } from 'vuex'

export default {
  name: 'MainImportantDatesProgress',
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'eventImportantDates',
      'eventImportantDatesRelativeStartDate',
    ]),
    importantDates () {
      return this.eventImportantDates
    },
    relativeStartDateString () {
      return this.eventImportantDatesRelativeStartDate
    },
  },
  methods: {
    formatDateMonthString (date) {
      return dayjs(date).format('MMM')
    },
    formatDateDayString (date) {
      return dayjs(date).format('DD')
    },
    daysUntil (date) {
      let endOfDate = dayjs(date).add(1, 'day')
      let diff = dayjs(endOfDate).diff(dayjs().startOf('day'), 'day')
      return diff
    },
    
    daysUntilString (date) {
      let endOfDate = dayjs(date)
      let diff = dayjs(endOfDate).diff(dayjs().startOf('day'), 'day')
      return `D-${diff}`
    },
    daysUntilPercentage (date) {
      const daysBetween = dayjs(date).diff(dayjs(this.relativeStartDateString), 'day')
      return (this.daysUntil(date) / daysBetween) * 100
    },
    colorScale (percentage) {
      let colorScale = chroma.scale([chroma(this.eventMainThemeColor).brighten(2).desaturate(1).hex(), this.eventMainThemeColor])
      let percentageInDecimalInverted = 1 - (percentage / 100)
    return colorScale(percentageInDecimalInverted).hex()
    },
    progressBarStyle (date) {
      let percentage = this.daysUntilPercentage(date)
      return `background-color:${this.colorScale(percentage)};width:${percentage}%;`
    },
  },
}
</script>
