<template>
  <div>
    <modal
      :name='modalName'
      :adaptive='true'
      height='auto'
      :max-width='maxWidth'
      width='100%'
      :shiftX='shiftXValue'
      :shiftY='shiftYValue'>
      <div class='relative flex flex-col justify-center items-center bg-gray-900 pb-16'>
        <button class='z-30 absolute top-3 right-3' @click="$modal.hide(modalName)">
          <x-icon class='text-black' />
        </button>
        <div v-html='onLoadModal.content'></div>
        <div class='bg-gray-100 py-3 absolute bottom-0 left-0 right-0 h-16'>
          <label class='flex flex-row justify-center items-center gap-x-1 p-2'>
            <input type='checkbox' v-model='saveHideModal' />
            <span>{{ saveHideModalButtonText }}</span>
          </label>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { XIcon } from 'vue-tabler-icons'

export default {
  name: 'OnLoadModal',
  props: [
    'onLoadModal',
  ],
  components: {
    XIcon
  },
  data () {
    return {
      saveHideModal: false,
    }
  },
  computed: {
    dialogFullscreenStatus () {
      return this.isMobile
    },
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    modalName () {
      return this.onLoadModal.name ? `on-load-modal-${this.onLoadModal.name}` : 'on-load-modal'
    },
    maxWidth () {
      return this.onLoadModal.maxWidth ? this.onLoadModal.maxWidth : 560
    },
    shiftX () {
      return this.onLoadModal.shiftX
    },
    shiftY () {
      return this.onLoadModal.shiftY
    },
    saveHideModalButtonText () {
      return this.onLoadModal.saveHideModalButtonText ? this.onLoadModal.saveHideModalButtonText : '다시 보지않기'
    },
    shiftXValue () {
      return !this.dialogFullscreenStatus && this.shiftX ? this.shiftX : 0.5
    },
    shiftYValue () {
      return !this.dialogFullscreenStatus && this.shiftY ? this.shiftY : 0.5
    },
  },
  watch: {
    saveHideModal: {
      handler: function (newVal) {
        if (newVal) {
          localStorage.setItem(`dont_show_modal_again-${this.modalName}`, 'saveHidden')
        } else {
          localStorage.removeItem(`dont_show_modal_again-${this.modalName}`)
        }
      }, 
    }
  },
  mounted () {
    if (localStorage.getItem(`dont_show_modal_again-${this.modalName}`) !== 'saveHidden') {
      this.$modal.show(this.modalName)
    }
  },
}
</script>
