<template>
  <div>
    <h2 class='font-semibold text-lg lg:text-xl uppercase'>{{ title }}</h2>
    <div v-if='emptyDownloadFiles'
      class='p-8 text-center uppercase text-gray-500'>
      {{ emptyMessage }}
    </div>
    <div v-else
      class='flex flex-row justify-start items-center gap-x-4 mt-4'>
      <a v-for='(downloadFile, index) in eventMainDownloadFiles'
        :key='`main-page-download-file-${index}`'
        class='h-32 w-32 flex-grow-0 flex-shrink-0 opacity-70 hover:opacity-100 text-center flex flex-col items-center justify-center rounded-md hover:shadow-md gap-y-2 uppercase text-sm'
        style='background-color:#FAF8FD;'
        :href='downloadFile.url' target='_blank'>
          <download-icon class='w-12 h-12 text-gray-500 opacity-90 mx-auto' />
          {{ downloadFile.title }}
        </a>
    </div>
  </div>
</template>

<script>
import { mapGetters }   from 'vuex'
import { DownloadIcon } from 'vue-tabler-icons'

export default {
  name: 'MainDownloadFiles',
  components: {
    DownloadIcon,
  },
  props: {
    title: {
      type: String,
      default: 'Download Center',
    },
    emptyMessage: {
      type: String,
      default: 'No files to download',
    },
  },
  computed: {
    ...mapGetters('events', [
      'eventMainDownloadFiles',
    ]),
    emptyDownloadFiles () {
      return this.eventMainDownloadFiles.length === 0
    },
  },
}
</script>
