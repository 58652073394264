<template>
  <div class='mt-8 w-full px-4 lg:px-0'>
    <h1 class='text-2xl font-semibold text-left pl-4 uppercase mb-2'>Key Speakers</h1>
    <div class='bg-gray-50 py-6'>
      <vue-slick-carousel v-if='whichSpeakers.length' 
        v-bind='carouselSettings'>
        <speaker-card v-for='speaker in whichSpeakers'
          :key='`speaker-${speaker.id}`'
          :card-width='speakerCardWidth'
          :speaker='speaker'
          class='bg-white flex-grow-0 flex-shrink-0'
          style='width:16rem; height:20rem; overflow-y:hidden;'
          @click.native='openSpeakerDetails(speaker.id)' />
      </vue-slick-carousel>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import cloneDeep        from 'lodash/cloneDeep'
import SpeakerCard      from '@/components/icola2023/MainSpeakerCard.vue'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'MainSpeakers',
  components: {
    SpeakerCard,
    VueSlickCarousel,
  },
  props: {
    title: {
      type: String,
      default: 'Speakers',
    },
    speakerCardWidth: {
      type: String,
      default: '14rem',
    }
  },
  data () {
    return {
      sortBy: 'first', // first, last
      speakers: [],
    }
  },
  computed: {
    emptySpeakers () {
      return this.whichSpeakers.length === 0
    },
    whichSpeakers () {
      let cloned = cloneDeep(this.speakers)
      return cloned.sort((a, b) => {
        const sortByA = a.filterTags[0].sortOrder || 100 // should ahve less than 100 main speakers, i hope
        const sortByB = b.filterTags[0].sortOrder || 100 // should ahve less than 100 main speakers, i hope
        if (sortByA < sortByB) {
          return -1
        } else if (sortByA > sortByB) {
          return 1
        } else {
          return 0
        }
      })
    },
    carouselSettings () {
      return {
        'arrows': false,
        'dots': false,
        'pauseOnHover': true,
        'variableWidth': true,
        'infinite': true,
        'slidesToShow': 1.5,
        'slidesToScroll': 1,
        'autoplay': true,
        'speed': 2000,
        'autoplaySpeed': 3000,
        'cssEase': 'linear'
      }
    },
  },
  methods: {
    ...mapActions([
      'openSpeakerDetailsModal',
    ]),
    ...mapActions('speakers', [
      'getMainSpeakers',
    ]),
    openSpeakerDetails (speakerId) {
      // don't open this for now
      console.log('speakerId', speakerId)
      // this.openSpeakerDetailsModal(speakerId)
    },
  },
  mounted () {
    this.getMainSpeakers().then(resp => {
      this.speakers = resp
    })
  },
}
</script>
