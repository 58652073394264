<template>
  <div>
    <div class='relative z-0'>
      <div class='pt-20 lg:pt-48'>
        <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/icola_main.png'
          class='full-width-inside-layout-container z-0'
          style='width:100vw;max-width:100vw;max-height:calc(100vh - 20rem);object-fit:cover;object-position:0 1rem;' />
        <div 
          class='z-10 absolute left-4 lg:left-0 w-4/5 lg:w-1/2'
          style='top:5%;'>
          <img 
            src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/icola_main_text.png'
            class='w-full ml-2 lg:ml-4' />
          <h2 class='text-xxs lg:text-xl font-semibold ml-2 lg:ml-4 mt-2 lg:mt-4 text-gray-500' v-html='heroContentCongressInfo.subtitleHtml'></h2>  
          <h3 
            class='tagline-with-background font-bold mt-2 lg:mt-4 rounded-full text-white italic text-left px-2 py-1 lg:px-4 lg:py-2'>
            Advancing Lipid and Atherosclerosis Research to the Next Level
          </h3>
          <img 
            src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/icola_date_and_location.png'
            class='w-3/5 lg:w-2/3 mt-2 lg:mt-4 ml-2 lg:ml-4' />
        </div>
        <div class='scroll-arrow'>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <div class='relative'>
      <content-background-cover
      background-color='#FAFAFA'
      class='z-0'
      :top-offset='0' />
      <div class='flex flex-col lg:flex-row px-2 lg:px-0 justify-between items-start lg:items-stretch gap-y-4 lg:gap-y-0 lg:gap-x-4' 
        style='margin-top:-4rem;'>
        <div class='w-full z-10 lg:w-1/2 flex-shrink-0 shadow-md bg-white opacity-95'>
          <main-speakers class='z-10' />
        </div>
        <main-important-dates-progress class='w-full z-10 lg:w-1/2 flex-shrink-0 shadow-md bg-white p-4 opacity-95' />
      </div>
      <div class='mt-4 lg:mt-8 flex flex-col lg:flex-row justify-between items-stretch gap-x-4'>
        <div class='w-full lg:w-1/2 px-2 lg:px-0 flex-shrink-0'>
          <div class='mb-4 p-4 shadow-md bg-white flex flex-row justify-between items-center'>
            <h1 class='text-left font-semibold text-lg lg:text-xl uppercase'>Local Weather</h1>
            <a href='https://www.kma.go.kr/eng/index.jsp' target='_blank' class='text-gray-600 text-sm uppercase flex flex-row justify-end items-center gap-x-2 opacity-80 hover:opacity-100'>
              Check Weather
              <sunset-2-icon class='inline-block w-6 h-6' />
            </a>
          </div>
          <main-download-files 
            title='Program Download'
            empty-message='Please check again soon.'
            class='mb-4 lg:mb-8 shadow-md bg-white p-4' />
          <div class='p-8 text-white'
            :style='eventColorBaseBackgroundGradient'>
            <div class='text-center my-0 lg:my-6 uppercase text-lg lg:text-3xl font-semibold'>{{isLiveMessage}}</div>
            <div v-if='false' class='flex flex-row justify-center gap-x-8 lg:gap-x-12 '>
              <div class='flex-shrink-0 flex-grow-0 text-center'>
                <h2 class='text-3xl lg:text-4xl font-semibold'>{{ daysLeft }}</h2>
                <h3 class='text-base uppercase'>Days</h3>
              </div>
              <div class='flex-shrink-0 flex-grow-0 text-center'>
                <h2 class='text-3xl lg:text-4xl font-semibold'>{{ hoursLeft }}</h2>
                <h3 class='text-base uppercase'>Hours</h3>
              </div>
              <div class='flex-shrink-0 flex-grow-0 text-center'>
                <h2 class='text-3xl lg:text-4xl font-semibold'>{{ minutesLeft }}</h2>
                <h3 class='text-base uppercase'>Minutes</h3>
              </div>
              <div class='flex-shrink-0 flex-grow-0 text-center'>
                <h2 class='text-3xl lg:text-4xl font-semibold'>{{ secondsLeft }}</h2>
                <h3 class='text-base uppercase'>Seconds</h3>
              </div>
            </div>
          </div> 
          <div class='my-4 lg:my-8'>
            <img
              @click='goToPage("JLA")'
              class='standard-transition w-full hover:shadow-md cursor-pointer opacity-85 hover:opacity-100'
              src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/jla_banner.png' />
          </div>
        </div>
        <div class='w-full lg:w-1/2 px-2 lg:px-0 flex-shrink-0'>
          <div class='flex flex-row justify-between gap-x-4 mb-4 lg:mb-8'>
            <button
              class='w-1/2 flex-grow-0 py-6 bg-gray-200 uppercase font-semibold text-white text-sm lg:text-base'
              style='background:linear-gradient(97.87deg, #1B85C3 -13.81%, #744D7D 132.15%);'
              @click='goToPage("AbstractSubmissions")'>
              Abstract Submission
            </button>
            <button
              class='w-1/2 flex-grow-0 py-6 bg-gray-200 uppercase font-semibold text-white text-sm lg:text-base'
              style='background:#744D7D;'
              @click='goToPage("Registration")'>
              Online Registration
            </button>
          </div>
          <notices-preview-list 
            class='shadow-md p-4 mb-4 lg:mb-8 bg-white'
            title="What's New"/>
          <div class='mb-4 lg:mb-8 p-4 shadow-md bg-white'>
            <h2 class='mb-4 font-semibold text-lg lg:text-xl uppercase'>Past Congresses</h2>
            <div class='flex flex-row justify-between'>
              <div 
                class='flex flex-col items-center justify-start p-2 cursor-pointer border border-transparent hover:border-gray-100 rounded-md bg-white hover:shadow-md'
                @click='goToPreviousIcola(2019)'>
                <img 
                  src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/logo_icola_2019.png' 
                  class='w-32 px-2' />
                <h3 class='font-semibold mt-4'>2019</h3>
              </div>
              <div 
                class='flex flex-col items-center justify-start p-2 cursor-pointer border border-transparent hover:border-gray-100 rounded-md bg-white hover:shadow-md'
                @click='goToPreviousIcola(2021)'>
                <img 
                  src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/logo_icola_2021.png' 
                  class='w-32 px-2' />
                <h3 class='font-semibold mt-4'>2021</h3>
              </div>
              <div 
                class='flex flex-col items-center justify-start p-2 cursor-pointer border border-transparent hover:border-gray-100 rounded-md bg-white hover:shadow-md'
                @click='goToPreviousIcola(2022)'>
                <img 
                  src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/logo_icola_2022.png' 
                  class='w-32 px-2' />
                <h3 class='font-semibold mt-4'>2022</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <welcome-sponsors-carousel class='bg-white' />
    </div>
    
    <on-load-modal
      v-for='(onLoadModal, index) in onLoadModals'
      :key='`onLoadModal-${index}`'
      :onLoadModal='onLoadModal' />
  </div>
</template>

<script>
import ContentBackgroundCover     from '@/components/ContentBackgroundCover.vue'
import MainSpeakers               from '@/components/MainSpeakers.vue'  
import NoticesPreviewList         from '@/components/NoticesPreviewList.vue'
import MainImportantDatesProgress from '@/components/MainImportantDatesProgress.vue'
import MainDownloadFiles          from '@/components/MainDownloadFiles.vue'
import WelcomeSponsorsCarousel    from '@/components/welcome/WelcomeSponsorsCarousel.vue'
import OnLoadModal                from '@/components/OnLoadModal.vue'
import { Sunset2Icon } from 'vue-tabler-icons'
import { mapGetters } from 'vuex'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'Main',
  components: {
    ContentBackgroundCover,
    NoticesPreviewList,
    MainSpeakers,
    MainImportantDatesProgress,
    MainDownloadFiles,
    WelcomeSponsorsCarousel,
    OnLoadModal,
    Sunset2Icon,
  },
  data () {
    return {
      rightNow: dayjs().tz('Asia/Seoul'),
    }
  },
  metaInfo: {
    meta: [
      { name: 'description', content: 'September 14 (Thu) ~ 16 (Sat), 2023. CONRAD Seoul, Korea. The 12th International Congress on Lipid & Atherosclerosis with Asia-Australia Vascular Biology Meeting' },
      // Other meta tags
    ],
  },
  computed: {
    ...mapGetters('events', [
      'eventColorBaseBackgroundGradient',
      'onLoadModals',
    ]),
    congressDate () {
      return new Date('2023-09-14 09:00:00')
    },
    diffMilSeconds () {
      return Math.abs(this.congressDate - this.rightNow)
    },
    daysLeft () {
      return Math.floor(this.diffMilSeconds / (1000 * 60 * 60 * 24))
    },
    hoursLeft () {
      let afterDays = this.diffMilSeconds - (this.daysLeft * 1000 * 60 * 60 * 24)
      return Math.floor(afterDays / (1000 * 60 * 60))
    },
    minutesLeft () {
      return dayjs(this.rightNow).endOf('hour').diff(dayjs(), 'minutes')
    },
    secondsLeft () {
      return dayjs(this.rightNow).endOf('minute').diff(dayjs(), 'seconds')
    },
    congressEndDate () {
      return dayjs.tz('2023-09-16 17:50:00', 'Asia/Seoul')
    },
    isLiveMessage () {
      return dayjs.tz(this.rightNow).isBefore(this.congressEndDate) ? 'ICoLA 2023 is Live!' : 'Thanks for attending ICoLA 2023!'
    },
    heroContentCongressInfo () {
      return {
        heroContentClass: `absolute top-24 right-0 text-right`,
        style: `color: #444; text-shadow: 1px 1px 1px #00000055;`,
        subtitleHtml: `The 12th International Congress on Lipid & Atherosclerosis<br> with Asia-Australia Vascular Biology Meeting`,
        titleHtml: `ICoLA2023`,
        subtitleDescriptionHtml: `in conjunction with AAVBM<br>with Asia-Australia Vascular Biology Meeting`,
        date: `September 14-16, 2023 <span class='uppercase font-normal opacity-70'>(THU - SAT)</span></h3>`,
        venue: `Conrad Hotel <span class='uppercase font-normal opacity-70'>(Seoul, South Korea)</span></h3>`,
      }
    },
  },
  methods: {
    goToPreviousIcola (year) {
      switch (year) {
        case 2019:
        window.open('http://2019.icola.org/', '_blank')
          break
        case 2021:
        window.open('http://2021.icola.org/', '_blank')
          break
        case 2022:
          window.open('https://icola2022.org', '_blank')
          break
      }
    },
    goToPage (pageName) {
      if (pageName === 'JLA') {
        window.open('https://www.e-jla.org/index.php', '_blank')
      } else {
        this.$router.push({ name: pageName })
      }
    },
  },
  mounted () {
    // update right now every second
    setInterval(() => {
      this.rightNow = new Date()
    }, 1000)
  }
}
</script>

<style lang='scss' scoped>
body {
    background-color: pink;
}

.scroll-arrow {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: rotate(0deg);
    cursor: pointer;
}

.scroll-arrow span {
    display: block;
    width: 1.5vw;
    height: 1.5vw;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}

.scroll-arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.scroll-arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

.tagline-with-background {
  width: 110%;
  font-size:10px;
  background-color:#345CA1;
}

@media (min-width: 768px) {
  .tagline-with-background {
    font-size:14px;
  }
}
@media (min-width: 1024px) {
  .tagline-with-background {
    width: 130%;
    font-size:20px;
  }
}

@media (min-width: 1440px) {
  .tagline-with-background {
    width: 120%;
    font-size:24px;
  }
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}
</style>
